<template>
  <div class="commonProblem">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">常见问题</div>
      <div class="rightBtn"></div>
    </div>

    <div class="main">
      <div class="cellBox">
        <router-link tag="div" to="/paymentIssues" class="cellItem">
          <div class="cellTitle">支付问题</div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </router-link>

        <router-link tag="div" to="/actIssues" class="cellItem">
          <div class="cellTitle">账号问题</div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </router-link>

        <router-link tag="div" to="/virusTips" class="cellItem">
          <div class="cellTitle">报毒提醒</div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </router-link>

        <router-link tag="div" to="/aboutUs" class="cellItem">
          <div class="cellTitle">关于我们</div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.commonProblem {
  height: 100%;
  // background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-bottom: 0.5px solid #f3f3f3;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      // flex: 2;
      // text-align: center;
      font-size: 16px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 12px 16px;
    box-sizing: border-box;
    .cellBox {
      // padding: 0 12px;
      box-sizing: border-box;
      border-radius: 10px;
      background: #fff;
    }

    .cellItem {
      display: flex;
      align-items: center;
      justify-content: space-between;

      height: 44px;
      font-size: 14px;
      border-bottom: 1px solid #e6e6e6;
      .cellTitle {
        color: #333;
        font-weight: 400;
      }

      .cellContent {
        color: #9f9eb1;
        flex: 2;
        text-align: end;
        margin-right: 12px;
      }

      .cellIcon {
        width: 12px;
        height: 12px;
      }

      .copyBtn {
        width: 46px;
        height: 19px;
        background: url("../../../assets/png/copyBtn.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .cellItem:last-child {
        border: none;
    }
  }
}
</style>
